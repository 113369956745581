.modal-container {
  text-align: center;
  /* margin-top: 20px; */
}

.custom-modal {
  position: absolute;
  width: 85vw;
  /* Adjust the width as needed */
  height: 84vh;
  text-align: center;
  background-color: white;
  padding: 15px;
  border: 2px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  /*========*/
  top: 48%;
  left: 48%;
  transform: translate(-40%, -42%);

}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.close-icon {
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  box-shadow: 0px 0px 5px 0.5px rgba(175, 174, 174, 0.637);
  width: 600px;
  height: 400px;
}
  .update-btn{
  background-color: #526bb1;
  color: white;
  border-radius: 5px;
}

.update-btn:hover,
.update-btn:active,
.update-btn:focus {
  background-color: #526bb1;
  color: white;
  border-radius: 5px;
}

/*========*/
.my-sidebar-container {
  background-color: white;
  padding-right: 10px;
  overflow-y: scroll;
  border-right: thin solid #e3e5e8;

}

.my-sidebar-container::-webkit-scrollbar {
  width: 8px;
  background-color: #ffff;
}

.my-sidebar-container::-webkit-scrollbar-track-piece {
  width: 10px;
  padding: 5px;
  margin-top: 3px;
  background-color: #ffff;
}

.my-sidebar-container::-webkit-scrollbar-thumb {
  width: 8px;
  border-radius: 10px;
  background-color: #c6caca;
}

.my-sidebar-container::-webkit-scrollbar-thumb:hover {
  background-color: #7c7777;
}