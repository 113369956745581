.headerMain {
    height: 50px;
    display: flex;
    align-items: center;
    /* flex-wrap:nowrap; */
    padding: 0 20px;
}

.headerCol {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-around;
    height: 45px;
    width: 5px;
}
.titleBarButtons{
    width: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    grid-gap: 12px;
}
.SavingSaved {
    display: flex;
    width: auto;
    margin-left: 8px;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

@media screen and (max-width: 480px) {
    .headerCol{
        align-items: center;
        justify-content: center;
    }    
    .titleBarButtons{
        width: fit-content;
        gap: 25px;
    }
    .headerMain {
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 20px;
    }
}


