@media print {
    #my-header,
    #my-toolbar,
    #offlineStatusBar,
    #my-sideBar,
    #chatbox-container,
    #mouseContainer,
    #speakerNotes,
    #delete-menu,
    #masterSlide-Conatiner,
    #slide-toolbar,
    #slide-layout {
        display: none !important;
    }

    @page {
        size: landscape;
        margin: 0;
    }

    .print-slide {
        page-break-inside: avoid;
    }
    
    .print-slide:not(:last-child) {
        page-break-after: always;
    }
}