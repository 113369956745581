.circleCanvas{
    /* position: relative; */
    padding: 1px;
    background-color: transparent;
    /* z-index: -100; */
}

.circle{
    position: absolute;
    z-index: 100;
}