.resizer{
    width: 100%;
    height: 9px;
    line-height: 0px;
    font-weight:900;
    letter-spacing:2px;
    border:thin solid #e3e5e8;
    text-align: center;
    color:#9e9e9e;
}
.resizer:hover{
    background-color: #eceff3;
    cursor: -moz-grab -webkit-grab;
    cursor: -webkit-grab;
}
.resizer:active{
    background-color: #d7dadf;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
}
.speakerNotesContainer{
    width: 86.48%;
    position: fixed;
    bottom: 0px;
    right: 0px;
    background-color: white;
    border: thin solid #e3e5e8;
    z-index: 999;
}