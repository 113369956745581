
.logo {
    height: 30px;
    width: 40px;
}
@media screen and (max-width: 768px) {
    .logo {
        height: 20px;
        width: 30px;
    }
    
}
