@media screen and (max-width: 480px) {
    .avatar {
        display: none;
    }

}

.no-profileImage-div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #CF9FFF;
    color: white;
    font-weight: 600;
}
.custom-modal-container {
    position: absolute;
    z-index: 9999;
    top: 50px;
    right: 25px;
    background-color: white;
    border: 2px solid rgba(192, 192, 192, 0.729);
    border-radius: 25px;
    width: 270px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.modal-profile-image {
    display: flex;
    justify-content: center;
    justify-items: center;
    margin-top: 15px;
}
.user-detail-container {
    border-radius: 30px;
    margin: 15px 8px;
    background-color: white;
    height: 150px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.user-detail-text {
    font-size: 15px;
}
.user-detail-label {
    font-size: 15px;
    font-weight: 600;
}