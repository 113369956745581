.toolbar{
    width: 98%;
    display: flex;
    align-items: center;
    background-color: white;
    /* height: 35px; */
    /* border-top: thin solid #e3e5e8;
    border-bottom: thin solid #e3e5e8;
    padding: 0px 20px; */
    /* box-shadow: 0px 0px 0px 4px 0.4px rgba(175, 174, 174, 0.479); */
    /*new style*/
    border: 1px solid silver;
    padding: 4px;
    margin-left: 26px;
    height: fit-content;
}
.toolbar > img{
    cursor: pointer;
    width: 22px;
    height: 22px;
    padding: 4px;
    margin: 0 1px;
}

.toolbar > img:hover{
    background-color: #e3e5e8;
    border-radius: 2px;
}
.separator{
    margin: 0 6px;
    border-left: 1px solid #bbb;
    flex-grow: 1;
    align-self: stretch;
}
.toolbar-btn{
    padding: 3px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    color: #464545;
    outline: none;
    border: none;
    background-color: white;
}
.toolbar-btn:hover{
    background-color: #a7a3a321;
    transition: background-color 0.3s ease-in-out;
    border-radius: 3px;
}
.slide-toolbar{
    display: flex;
    align-items: center;
}

.sticky{
    /* display: flex; */
}


@media screen and (max-width: 992px) {
    .toolbar{ 
        /* display: none; */
        flex-direction: column;
    }
    .sticky{
        z-index: 500;
        background-color: white;
    }
    .sticky span {
        margin-right: 0;
    } 
  }
/* @media screen and (max-width: 768px) {
    .toolbar{ 
        display: none; 
        flex-direction: column;
    }
    .sticky{
        z-index: 500;
        background-color: white;
    }
    .sticky span {
        margin-right: 0;
    } 
}
@media screen and (max-width: 490px) {
    .toolbar{ 
        display: none; 
        flex-direction: column;
    }
    .sticky{
        z-index: 500;
        background-color: white;
    }
    .sticky span {
        margin-right: 0;
    } 
  } */