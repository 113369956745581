.menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
    width: 630px;
  }
  
  .menu-item {
    width: calc(34.33% - 16px); 
    flex: 1 0 33.3333%;
    padding: 5px;
    text-align: center;
  }
  .img-div
  {
    width: 200px;
    /* border: 2px solid red; */
  }
  .menu-item img {
    border: 2px solid rgb(128 128 128 / 19%);
    width: 85%;
  }
  
  .menu-item span {
    display: block;
    margin-top: 5px;
    text-align: center;
  }
  @media (max-width: 892px) {
    .menu {
        width: 600px;
      }
    .menu-item {
      width: calc(36% - 12px); 
      margin: -8px;
    }
  }
  @media (max-width: 636px) {
    .menu {
        width: 400px;
      }
    .menu-item {
      width: calc(42% - 12px); 
      margin: -9px;
    }
  }
  @media (max-width: 480px) {
    .menu {
        width: 384px;
      }
    .menu-item {
      width: calc(44% - 16px);
    }
  }
  @media (max-width: 375px) {
    .menu {
        width: 350px;
      }
    .menu-item {
      width: calc(44% - 16px);
    }
    .img-div
  {
    width: 160px;
    /* border: 2px solid red; */
  }
  }