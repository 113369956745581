/* .slide{
    width: 600px;
    height: 350px;
    margin: 0 auto;
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    padding: 10px;
} */

/* .sidebar-slide{
    width: 150px;
    height: 100px;
    border: 2px solid #e3e5e8;
    border-radius: 5px;
    margin: 5px auto;
    font-size: 5px;
    padding: 5px;
}    */

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 0.3s ease-out;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 0.3s ease-out;
}