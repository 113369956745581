input[type="file"]{
    display: none;
}
.navigationmain {
    /* display: flex; */
    font-size: 14px;
    font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
    /* width: 20px; */
    color: #464749;
    padding-left: 10px;
    cursor: pointer;
}


@media screen and (max-width: 480px){
    .navigationmain{
        display: none;
    }
}
/*Roboto,RobotoDraft,Helvetica,Arial,sans-serif*/