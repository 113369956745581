.title-container{
    padding:2px 5px ;
    display: flex;
}

.title{
    height: 20px;
    width: fit-content;
    border: none;
    /* padding: 12px 5px; */
    letter-spacing: 1px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin: 7px 0px 0px 5px;
}
.title:focus{
    outline:none;
}


@media screen and (max-width: 768px) and (orientation:portrait) {
    .title{
        margin: 0;
    }
}