.zoom{
  zoom: 160%;
}

.slide-container {
  width: 1200px;
  height: 700px;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0.5px rgba(175, 174, 174, 0.637);
  position: relative;
  overflow: visible;
}

.add-new-slide{
  width: 50%;
  height: 40vh;
  border: 3px dashed #808080;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-size: 1.5rem;
  color: #808080;
  font-style: oblique;
  cursor: pointer;
}

.sidebar-container{
  height: 87vh;
  background-color: white;
  padding-right: 10px;
  overflow-y: scroll;
  border-right: thin solid #e3e5e8;
}
.sider-slide {
  width: 200px;
  height: 110px;
  border: 2px solid #e3e5e8;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.sider-slide-container {
  width: 230px;
  height: 120px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.sider-slide:hover {
  border: 3px solid #d2d4d8;
  transition: border 0.2s ease-in-out;
}
.sider-slide-container.selectedSlide > .sider-slide{
  border: 2px solid #6eb7fa;
}

.sider-slide-container.selectedSlide {
  background-color: #4a9eec31;
}

.border {
  border: 1px solid #dfdbdb !important;
}

.ql-container.ql-snow {
  border: none;
}
.ql-picker-options{
  z-index: 100000 !important;
  height: 300px;
  overflow-y: scroll;
  text-align: center;
}
.ql-picker-options::-webkit-scrollbar {
  width: 5px;
  background-color: #ffff;
}
.ql-picker-options::-webkit-scrollbar-track-piece {
  width: 7px;
  padding: 5px;
  margin-top: 3px;
  background-color: #ffff;
}
.ql-picker-options::-webkit-scrollbar-thumb {
  width: 8px;
  border-radius: 10px;
  background-color: #c6caca;
}
.ql-picker-options::-webkit-scrollbar-thumb:hover {
  background-color: #7c7777;
}

.ql-container {
  padding: 0.2%;
}
.ql-editor {
  padding: 0px;
}
.ql-editor p{
  line-height: 1;
}

.shapeText > .ql-editor{
  font-size: 2rem;
  overflow: visible;
}

.title > .ql-editor {
  height: 100%;
  /* font-size: 3rem; */
  overflow: visible;
  z-index: 100;
  font-size: 42px;
}

.text > .ql-editor {
  height: 100%;
  /* font-size: 2.5rem; */
  overflow: visible;
  z-index: 100;
  font-size: 42px;
}

/* .ql-editor > p{
  height: fit-content;
} */
.footer::after {
  content: "|";
}
.ql-tooltip {
  z-index: 99;
}
.selected {
  border: 3px solid #6eb7fa !important;
}

.image-upload > input {
  display: none;
}

.image {
  position: absolute;
}

.image > img {
  width: 100%;
  height: 100%;
}


.sidebar-container::-webkit-scrollbar {
  width: 8px;
  background-color: #ffff;
}
.sidebar-container::-webkit-scrollbar-track-piece {
  width: 10px;
  padding: 5px;
  margin-top: 3px;
  background-color: #ffff;
}
.sidebar-container::-webkit-scrollbar-thumb {
  width: 8px;
  border-radius: 10px;
  background-color: #c6caca;
}
.sidebar-container::-webkit-scrollbar-thumb:hover {
  background-color: #7c7777;
}
.main-container::-webkit-scrollbar {
  width: 8px;
  background-color: #f0f2f5;
}
.main-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c6caca;
}
.resizer {
  width: 10px;
  height: 10px;
  border-radius: 50%; /*magic to turn square into circle*/
  background: white;
  border: 3px solid #4286f4;
  position: absolute;
}

.resizer.top-left {
  left: -6px;
  top: -6px;
  cursor: nwse-resize; /*resizer cursor*/
}
.resizer.top-right {
  right: -7px;
  top: -7px;
  cursor: nesw-resize;
}
.resizer.bottom-left {
  left: -6px;
  bottom: -6px;
  cursor: nesw-resize;
}
.resizer.bottom-right {
  right: -6px;
  bottom: -6px;
  cursor: nwse-resize;
}
.resizer.top {
  top: -7px;
  left: 50%;
  cursor: ns-resize;
}
.resizer.right {
  right: -7px;
  top: 45%;
  cursor: ew-resize;
}
.resizer.bottom {
  bottom: -7px;
  left: 50%;
  cursor: ns-resize;
}
.resizer.left {
  left: -7px;
  top: 45%;
  cursor: ew-resize;
}

.hidden {
  display: none;
}

.ql-toolbar.ql-snow {
  border: none;
}
.ql-formats{
  margin: 0 !important;
  padding: 2px 0 0 0;
}

.ql-formats::before{
  content: '|' !important;
  height: 30px;
  font-size: 15px;
  font-weight: lighter;
  color: #9ea1ad;
}

.menu {
  list-style-type: none;
  padding: 5px;
  text-align: left;
  font-size: 14px;
}
.menu > li {
  padding: 5px;
}
.menu > li:hover {
  cursor: pointer;
  background-color: #dddfe071;
}
.menu-container {
  width: 200px;
  height: fit-content;
  background-color: #fdfdfd;
  border-radius: 3px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.199);
  position: absolute;
  z-index: 100000;
}
.sub-menu {
  position: absolute;
  width: 60%;
  top: 0;
  left: 100%;
  margin-top: 20px;
  margin-left: 3px;
  z-index: 1;
  background-color: #fdfdfd;
  border-radius: 3px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.199);
  list-style-type: none;
}

.sub-menu > li {
  padding: 5px;
  margin-left: -35px;
  text-decoration: none;
}

.sub-menu > li:hover {
  cursor: pointer;
  background-color: #dddfe071;
}

.disable {
  cursor: default !important;
  color: #b4b8b8;
}

.shape{
  z-index: 5;
}

.shape-menu-item:hover{
  background-color: white !important;
}

.shape-item {
  padding: 0px 3px 3px 3px;
}
.shape-item:hover{
  border-radius: 3px;
  background-color: #efefef;
}

.present-icon:hover{
  color: #1890ff !important;
}

.ant-btn:hover{
  background-color: #188fff0e;
  color: #000000d9;
  border: 1px solid #1c91ff6e;
}
.ant-dropdown-link:hover{
  background-color: #d3d3d369;
  border-radius: 3px;
}


/* .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: '10px';
  font-size: 10px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: '18px';
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: '32px';
  font-size: 24px !important;
} */

/* new style of font size and family */

/*Adding the values from the array which has the values as data-values*/

/*for font-size*/

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}


/*for font-family*/

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}

.ql-snow .ql-picker.ql-font {
  width: 150px !important;
  white-space: wrap;
}

.ql-snow .ql-picker.ql-lineheight{
  width: 50px;
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.0']::before {content: '1.0';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.0']::before {content: '1.0' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.2']::before {content: '1.2';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.2']::before {content: '1.2' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.5']::before {content: '1.5';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.5']::before {content: '1.5' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.6']::before {content: '1.6';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.6']::before {content: '1.6' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.8']::before {content: '1.8';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.8']::before {content: '1.8' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.0']::before {content: '2.0';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.0']::before {content: '2.0' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.4']::before {content: '2.4';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.4']::before {content: '2.4' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.8']::before {content: '2.8';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.8']::before {content: '2.8' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='3.0']::before {content: '3.0';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='3.0']::before {content: '3.0' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='4.0']::before {content: '4.0';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='4.0']::before {content: '4.0' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='5.0']::before {content: '5.0';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='5.0']::before {content: '5.0' !important;}

@media screen and (min-width: 2200px) {
  .slide-container {
    width: 1800px;
    height: 1020px;
  }
  .sider-slide {
    width: 250px;
    height: 150px;
  }
  
  .sider-slide-container {
    width: 300px;
    height: 160px;
  }
}

@media screen and (max-width: 1540px) {
  .slide-container {
    width: 960px;
    height: 540px;
  }
  .sider-slide {
    width: 150px;
    height: 100px;
  }
  
  .sider-slide-container {
    width: 180px;
    height: 110px;
  }
  .title > .ql-editor {
    font-size: 2.55rem;
  }
  
  .text > .ql-editor {
    font-size: 2rem;
  }
  .shapeText > .ql-editor {
    font-size:  1.6rem;
  }

  .sidebar-container::-webkit-scrollbar {
    width: 6px;
    background-color: #ffff;
  }
  .sidebar-container::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 8px;
    background-color: #c6caca;
  }
}
@media screen and (max-width: 1200px) {
  .slide-container {
    width: 848px;
    height: 500px;
  }
  .sider-slide {
    width: 110px;
    height: 80px;
  }
  
  .sider-slide-container {
    width: 140px;
    height: 100px;
  }
  .title > .ql-editor {
    font-size: 2.3rem;
  }
  
  .text > .ql-editor {
    font-size: 1.7rem;
  }
  .shapeText > .ql-editor {
    font-size:  1.5rem;
  }
  /* .ql-toolbar.ql-snow{
    display: flex;
    flex-wrap: nowrap;
  }
  .ql-toolbar.ql-snow > span{
    display: flex;
    margin-right: 0;
  } */
}
@media screen and (max-width: 992px) {
  .main-container{
    padding-top: 80px !important;
  }
  .slide-container {
    width: 700px;
    height: 400px;
  }
  .sider-slide {
    width: 100px;
    height: 60px;
  }
  .sider-slide-container {
    width: 120px;
    height: 70px;
    margin-top: 0;
    margin-right: 10px;
  }
  
  .sidebar-container{
    align-items: center;
    height: 10vh;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .ant-layout-sider-children{
    display: flex;
    overflow-x: scroll;
  }
  
  .sider-slide-container > span {
    display: none;
  }
  .title > .ql-editor {
    font-size: 1.8rem;
  }
  
  .text > .ql-editor {
    font-size: 1.5rem;
  }
  .shapeText > .ql-editor {
    font-size:  1rem;
  }
  .ql-toolbar.ql-snow{
    border: thin solid #e3e5e8;
  }
}

@media screen and (max-width: 768px) {
  .main-container{
    padding-top: 150px !important;
  }
  .slide-container {
    width: 490px;
    height: 290px;
  }

  .sider-slide {
    width: 80px;
    height: 50px;
  }
  .sider-slide-container {
    width: 100px;
    height: 60px;
    margin-top: 0;
    margin-right: 10px;
  }
  
  .sidebar-container{
    align-items: center;
    height: 10vh;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .ant-layout-sider-children{
    display: flex;
    overflow-x: scroll;
  }

  .shapeText > .ql-editor {
    font-size:  100%;
  }
  .ql-toolbar.ql-snow{
    border: thin solid #e3e5e8;
  }
}
@media screen and (max-width: 490px) {
  .main-container{
    padding-top: 150px !important;
  }
  .slide-container {
    width: 300px;
    height: 180px;
  }
  .sider-slide {
    width: 60px;
    height: 40px;
  }
  .sider-slide-container {
    width: 80px;
    height: 50px;
    margin-top: 0;
    margin-right: 10px;
  }
  
  .sidebar-container{
    align-items: center;
    height: 10vh;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .ant-layout-sider-children{
    display: flex;
    overflow-x: scroll;
  }

  .title > .ql-editor > * {
    font-size: 0.8rem !important;
  }
  .text > .ql-editor > * {
    font-size: 0.6rem !important;
  }
  .shapeText > .ql-editor {
    font-size:  0.5rem;
  }
  .ql-toolbar.ql-snow{
    border: thin solid #e3e5e8;
  }
}
