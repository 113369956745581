/* General Styles */
.quillToolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.ql-toolbar.ql-snow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
  font-family: '';
  padding: 4px !important;
  border: none !important;
}

/* Picker Styles */
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  position: relative !important;
  right: -8px !important;
  top: 28% !important;
  width: 18px !important;
}

.ql-toolbar.ql-snow .ql-background .ql-picker-options,
.ql-toolbar.ql-snow .ql-color .ql-picker-options {
  width: 198px !important;
  height: auto !important;
}

/* Font Size Picker Display */
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: '42px';
}

/* Separator Styles */
.ql-formats::before {
  content: '' !important;
  margin: 0 6px;
  border-left: 1px solid #bbb;
  flex-grow: 1;
  align-self: stretch;
}

.ql-toolbar.ql-snow .ql-formats:first-child::before {
  content: none !important; /* Remove separator before the first child */
  border: none !important;
  margin: 0;
}

/* General Toolbar Styles */
.ql-snow .ql-formats {
  display: flex;
  padding: 0 !important;
}

.ql-snow .ql-picker.ql-size,
.ql-snow .ql-picker.ql-header,
.ql-snow .ql-picker.ql-font {
  width: auto !important;
}
.ql-snow .ql-picker.ql-align .ql-picker-options {
  height: auto !important;
}